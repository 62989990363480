



/* sm */
.wrapperIletisimSosyalMedya {
    display: inline-flex;
    list-style: none;
  }
  
  .wrapperIletisimSosyalMedya .iconIletisimSosyalMedya {
    position: relative;
    background: #ffffff;
    border-radius: 50%;
    padding: 15px;
    margin: 10px;
    width: 50px;
    height: 50px;
    font-size: 18px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    box-shadow: 0 10px 10px rgba(0, 0, 0, 0.1);
    cursor: pointer;
    transition: all 0.2s cubic-bezier(0.68, -0.55, 0.265, 1.55);
  }
  
  .wrapperIletisimSosyalMedya .tooltip {
    position: absolute;
    top: 0;
    font-size: 14px;
    background: #ffffff;
    color: #ffffff;
    padding: 5px 8px;
    border-radius: 5px;
    box-shadow: 0 10px 10px rgba(0, 0, 0, 0.1);
    opacity: 0;
    pointer-events: none;
    transition: all 0.3s cubic-bezier(0.68, -0.55, 0.265, 1.55);
  }
  
  .wrapperIletisimSosyalMedya .tooltip::before {
    position: absolute;
    content: "";
    height: 8px;
    width: 8px;
    background: #ffffff;
    bottom: -3px;
    left: 50%;
    transform: translate(-50%) rotate(45deg);
    transition: all 0.3s cubic-bezier(0.68, -0.55, 0.265, 1.55);
  }
  
  .wrapperIletisimSosyalMedya .iconIletisimSosyalMedya:hover .tooltip {
    top: -45px;
    opacity: 1;
    visibility: visible;
    pointer-events: auto;
  }
  
  .wrapperIletisimSosyalMedya .iconIletisimSosyalMedya:hover span,
  .wrapperIletisimSosyalMedya .iconIletisimSosyalMedya:hover .tooltip {
    text-shadow: 0px -1px 0px rgba(0, 0, 0, 0.1);
  }
  
  .wrapperIletisimSosyalMedya .facebook:hover,
  .wrapperIletisimSosyalMedya .facebook:hover .tooltip,
  .wrapperIletisimSosyalMedya .facebook:hover .tooltip::before {
    background: #1877F2;
    color: #ffffff;
  }
  
  .wrapperIletisimSosyalMedya .twitter:hover,
  .wrapperIletisimSosyalMedya .twitter:hover .tooltip,
  .wrapperIletisimSosyalMedya .twitter:hover .tooltip::before {
    background: #1DA1F2;
    color: #ffffff;
  }
  
  .wrapperIletisimSosyalMedya .instagram:hover,
  .wrapperIletisimSosyalMedya .instagram:hover .tooltip,
  .wrapperIletisimSosyalMedya .instagram:hover .tooltip::before {
    background: #E4405F;
    color: #ffffff;
  }
  
  .wrapperIletisimSosyalMedya .github:hover,
  .wrapperIletisimSosyalMedya .github:hover .tooltip,
  .wrapperIletisimSosyalMedya .github:hover .tooltip::before {
    background: #333333;
    color: #ffffff;
  }
  
  .wrapperIletisimSosyalMedya .youtube:hover,
  .wrapperIletisimSosyalMedya .youtube:hover .tooltip,
  .wrapperIletisimSosyalMedya .youtube:hover .tooltip::before {
    background: #CD201F;
    color: #ffffff;
  }
  /* sm */

  
  iframe {
    border-radius: 25px;
  }